.image-gallery {
    .pane-toolbar--flat-desktop {
        position: fixed;
        width: 100%;
        background-color: var(--color-white);
    }
    background-color: var(--color-white);
    .MuiDialog-paperFullScreen {
        background-color: var(--color-white);
    }

    .gallery_content-area {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 100px;
        width: 100%;
        align-self: center;
        margin-top: 133px;
        .cn__card {
            height: auto;
            width: unset !important;
        }
        @media (max-width: 618px) {
            margin-top: 0px;
        }
        .photo-gallery--loading {
            display: grid;
            row-gap: 10px;
        }

        .gallery_content-area_header {
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            @media (max-width: 618px) {
                margin-bottom: 42px;
            }
            @media (min-width: 618px) {
                padding-top: initial;
                margin-top: 37px;
                display: grid;
                grid-template-columns: 1fr max-content;
                column-gap: 10px;
                margin-bottom: 20px;
            }
        }

        .grs-label {
            align-self: flex-end;
            width: max-content;
            margin-top: 0;
            margin-right: 0;
            top: 20px;
            right: 20px;
            left: unset;
            bottom: unset;
            margin: 0;
            background-color: var(--color-white);
            padding: 0px 10px;
            display: flex;
            font-size: 1rem;
            line-height: 150%;
            font-weight: 600;
            color: var(--color-pure-black);
            border-radius: 12px 12px 12px 0px;
            border: 1px solid #E0E0E3;
            margin-bottom: 0px;
            .grs-rating_number {
                align-content: center;
            }
            @media (min-width: 618px) {
                margin-top: 20px;
                margin-right: 20px;
            }
        }

        .property-title {
            .property-title_product-title {
                margin-bottom: 8px;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: -0.01em;

                @media (min-width: 618px) {
                    line-height: initial;
                    font-size: 32px;
                    letter-spacing: -0.02em;
                }
            }

            .property-title_sub-header-main {
                border: none;
            }

            .property-title_sub-header-additional {
                display: none;
            }
        }
    }

    @media (min-width: 1136px) {
        .gallery_content-area {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    @media (min-width: 1440px) {
        .gallery_content-area {
            padding-left: calc((100vw - 1340px) / 2);
            padding-right: calc((100vw - 1340px) / 2);
            max-width: unset;
        }
    }

    @media (min-width: 1600px) {
        .gallery_content-area {
            align-self: initial;
            padding-left: calc((100vw - 1410px) / 2);
            padding-right: calc((100vw - 1410px) / 2);
        }
    }
}

.gallery_lightbox {
    .lightbox_overlay {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 9999;
        background-color: rgba(0, 0, 0, .8);
        display: grid;
        place-content: center;
        outline: 0;
    }

    .lightbox_content {
        outline: 0;
        display: inherit;
        place-content: inherit;
        // max-height: calc(100vh - 90px);
        // height: calc(100vh - 90px);
        // padding: 5px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // position: relative;
        // outline: 0;
        // padding: 0 50px;
    }

    .lightbox_header {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        max-width: 1280px;
        display: flex;
        justify-content: flex-end;

        @media (min-width: 900px) {
            padding-bottom: 30px;
        }

        .lightbox_close-action {
            cursor: pointer;

            .ic-icon {
                svg {
                    path {
                        stroke: var(--color-white);
                    }
                }
            }
        }
    }

    .slider_main {
        width: 100vw;
        max-width: 1280px;
        overflow: hidden;

        .slider_main_item {
            text-align: center;
            padding-right: 20px;
            padding-left: 20px;

            img {
                object-fit: cover;
                height: 62.4vw;
                //width: min(1110px, 100%);
                width: 100%;
                max-width: 1154px;
                border-radius: 16px;
                max-height:573px;

                @media (min-width: 600px) {
                    height: calc(var(--vh, 1vh)*100 - 270px);
                    object-fit: inherit;
                }
            }

            .slider_main_caption {
                color: var(--color-white);
                margin-top: 10px;
                width: min(1110px, 100%);
                margin-left: auto;
                margin-right: auto;
                overflow: hidden;
                line-clamp: 2;
                box-orient: vertical;
                display: flex;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .slick-arrow {
            width: 38px;
            height: 38px;
            border: 2px solid var(--color-white);
            border-radius: 50%;

            &.slick-prev {
                left: 10px;
                svg {
                    transform: rotate(-180deg);
                }
            }

            &.slick-next {
                right: 10px;
                svg {
                    transform: rotate(180deg);
                }
            }

            strong {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                svg {
                    width: 16px;
                    height: 16px;
                    path {
                        stroke: var(--color-white);
                        stroke-width: 4;
                    }
                }
            }
        }
    }

    .slider_nav {
        width: 100%;
        max-width: 820px;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        margin-top: 30px;

        /* @media (min-width: 1280px) {
            width: 70vw;
        } */

        .slick-current {
            .slider_nav_item {
                &::before {
                    opacity: 0;
                }
            }
        }

        .slider_nav_item {
            text-align: center;
            padding-right: 5px;
            padding-left: 5px;
            position: relative;
            border-radius: 10px;

            &::before {
                content: '';
                background-image: linear-gradient(0deg, rgba(233, 233, 235, 0.7), rgba(233, 233, 235, 0.7));
                position: absolute;
                top: 0;
                left: 5px;
                height: 89px;
                width: 94px;
                border-radius: 10px;
                opacity: 1;
                transition: opacity .3s ease-in-out;
                cursor: pointer;
            }

            img {
                height: 89px;
                width: 94px;
                object-fit: cover;
                border-radius: 10px;
                cursor: pointer;
            }
        }
    }

    .slide-counter {
        margin-top: 27px;
        text-align: center;
        color: var(--color-white);
        @media (min-width: 600px) {
            margin-top: 17px;
        }
    }

    .progress-bar {
        margin-top: 26px;
        padding-right: 20px;
        padding-left: 20px;

        .progress-bar_track {
            display: flex;
            align-items: center;
            background-color: var(--color-light-grey);
            height: 2px;
            width: 100%;
            border-radius: 10px;

            .progress-bar_filler {
                height: 4px;
                background-color: rgba(244, 102, 162, .6);
                border-radius: inherit;
                transition: width .3s ease-in-out;
            }
        }
    }

    /*.carousel-slider--gallery {
        width: 100vw;
        max-width: 1280px;
        display: grid;
        grid-template-rows: 1fr 145px;
        grid-template-columns: 100%;

        .carousel-slider__item {
            text-align: center;

            height: calc(var(--vh, 1vh)*100 - 235px);
            max-width: min(1100px, 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            padding-top: 30px;
            margin: auto;

            img {
                border-radius: 20px;
                object-fit: contain;
                max-height: calc(var(--vh, 1vh)*100 - 270px);
                max-width: min(1100px, 100%);
                width: 100%;

                @media (min-width: 1136px) {
                    width: auto;
                }
            }
        }

        .carousel-slider__navigation {
            align-self: center;
            width: 65vw;
            margin: 0 auto;
            padding: 0;

            .slick-slider {
                overflow: hidden;
                // height: 100px;
                // .slick-track {
                // height: 100px !important;
                // overflow: hidden;
                // }
            }

            .slick-slide {
                &.slick-current {
                    .carousel-slider__item {
                        filter: none;
                        // .carousel-slider__item__cover img {
                        //     filter: none;
                        // }
                    }
                }

                // width: 110px !important;
                // height: 89px;
                .carousel-slider__item {
                    border-radius: 10px;
                    filter: brightness(0.6) invert(1);
                    // .carousel-slider__item__cover {
                    //     img {
                    //         // width: 94px;
                    //         // height: 89px;
                    //         border-radius: 10px;
                    //         filter: brightness(0.6) invert(1);
                    //     }
                    // }
                }
            }
        }
    }*/
}

.image-gallery--open {
    overflow: hidden;
    height: 100%;
    .DesktopHeader__wrapper {
        display: none;
    }
    [data-rsbs-overlay] {
        height: 100vh;
        --rsbs-overlay-h: 100vh;
        z-index: 9;
        @media (max-width: 600px) {
            top: 37px;
        }
    }
    [data-rsbs-scroll] {
        background: var(--color-white) ;
    }
}