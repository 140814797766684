.cn__dashboard_property_card_container {
	margin-bottom: rem(40);
	box-shadow: 0 rem(5) rem(30) rgba(231, 228, 228, 0.5);
}

.cn__dashboard_watchlist_property_card {
	.cn__dashboard_property_card__imginfo {
		@media (min-width: 1460px) {
			max-width: 33.764% !important;
			padding: 20px 12px 20px 20px !important;
		}
	}
}

.cn__dashboard_property_card {
	animation: appear 0.05s ease-in-out;
	width: 100%;
	border-radius: rem(20);
	display: grid;
	grid-template-columns: 33.8% 66.2%;
	background-color: $color-white;
	transition: 0.3s ease-in-out;
	position: relative;
	cursor: pointer;
	overflow: hidden;

	@media (max-width: 622px) {
		grid-template-columns: 100%;
	}

	&:hover {
		.cn__dashboard_property_card_image-slider {
			.slider-nav-control {
				opacity: 1;
			}
		}
		.cn__dashboard_property_card_footer {
			.cn__dashboard_property_card_rating {
				.cn__dashboard_property_card_rating-icon {
					path {
						fill: $color-primary;
					}
				}
			}
		}
		// box-shadow: 0 rem(2) rem(20) rgba(87, 75, 144, .2);
	}

	.cn__dashboard_property_card__imginfo {
		position: absolute;
		padding: 20px 12px 20px 20px;
		display: flex;
		justify-content: space-between;
		z-index: 1;

		@media (min-width: 600px) {
			width: 33.764%;
		}

		.cn__dashboard_property_card__imginfo__tags {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			gap: 5px;
		}
		@media (max-width: 600px) {
			width: 100%;
			padding: 0.9375rem;
		}

		@media (min-width: 1460px) {
			width: 100%;
			padding: 20px;
		}
	}

	.cn__dashboard_property_card_wishlist-marker {
		font-family: $primary-font;
		position: relative;
		border: none;
		cursor: pointer;
		outline: none;
		width: rem(40);
		height: rem(40);
		border-radius: 50%;
		background: $color-subtle-white;
		display: flex;
		z-index: 1;
		path {
			stroke: #c4c4c4;
			stroke-width: rem(1);
		}
		.cn__dashboard_property_card_wishlist-marker-icon {
			margin: auto;
			&.cn__dashboard_property_card_wishlist-marker-icon__selected {
				path {
					fill: unset;
					fill: #f35597;
					stroke: unset;
					stroke-width: 0;
				}
			}
		}
	}
	.cn__dashboard_property_card_slider-container {
		position: relative;
		overflow: hidden;
		// border-top-left-radius: rem(20);
		// border-bottom-left-radius: rem(20);
	}

	.cn__dashboard_property_card_sticker {
		display: none;
		padding: rem(3) rem(10);
		position: absolute;
		bottom: rem(15);
		left: rem(15);
		z-index: 1;
		color: $color-purple;
		font-family: $primary-font;
		font-weight: 500;
		font-size: rem(12);
		text-transform: uppercase;
		letter-spacing: 0.02em;
		// font-variant: small-caps;
		line-height: 1rem;
		border-radius: rem(6);
		background-color: $color-white;
	}
	.cn__dashboard_property_card_mobile-attrs {
		position: absolute;
		bottom: rem(15);
		display: grid;
		// column-gap: rem(15);
		grid-template-columns: max-content auto;
		padding-left: rem(15);
		padding-right: rem(15);
		width: 100%;
		align-items: flex-end;
		&_grsinfo {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 10px;

			.grs-rating {
				order: 3;
			}
			.cn__dashboard_property_card_import_source {
				order: 1;
			}
		}
	}
	.cn__dashboard_property_card_image-slider {
		.slick-slide {
			opacity: 0;
			transition: opacity 0.5s ease-in-out;
		}

		.slick-active {
			opacity: 1;
			transition: opacity 0.5s ease-in-out;
		}
		/*.cn__dashboard_property_card_image */
		&.react-loading-skeleton {
			height: 100%;
		}
		* {
			outline: transparent;
		}
		// background-repeat: no-repeat;
		// background-size: cover;
		// background-position: center;
		// width: rem(250);
		// border-top-left-radius: rem(20);
		// border-bottom-left-radius: rem(20);
		// -webkit-mask-image: -webkit-radial-gradient(white, black);
		overflow: hidden;
		img {
			object-fit: cover;
			max-width: 100%;
			height: 238px;
		}
		.slick-dots {
			bottom: rem(5);
			li {
				margin: 0;
				button::before {
					background-color: $color-subtle-gray;
				}
				&.slick-active {
					button::before {
						background-color: $color-white;
					}
				}
			}
		}
		.slider-nav-control {
			transition: 0.2s ease-in-out;
			opacity: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(24);
			height: rem(24);
			box-shadow: 0 rem(1) rem(2) rgba(36, 37, 38, 0.2);
			background-color: $color-white;
			border-radius: 50%;
			z-index: 1;
			&.slick-next {
				right: rem(10);
				.slider-nav-control_icon {
					transform: rotate(-90deg) translateY(rem(1));
				}
			}
			&.slick-prev {
				left: rem(10);
				.slider-nav-control_icon {
					transform: rotate(90deg) translateY(rem(1));
				}
			}
		}
		.slick-slide {
			padding: 0;
			background: linear-gradient(
				35.88deg,
				rgba(0, 0, 0, 0.5) 8.86%,
				rgba(0, 0, 0, 0) 41.14%
			);
		}
	}
	.cn__dashboard_property_card_content-wrapper {
		padding: 20px 20px 20px 10px;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;

		@media screen and (max-width: 600px) {
			height: 170px;
		}

		.cn__dashboard_property_card_content {
			color: $color-gray-font;
			font-size: 1rem;
			.cn__dashboard_property_card_name {
				width: 75%;
				@media (min-width: 1460px) {
					width: 100%;
				}
				@media (max-width: 600px) {
					width: 100%;
				}
				&.cn__dashboard_property_card_name-full {
					width: 100%;
				}
				&__wrapper {
					position: relative;
					width: 92%;
					overflow: hidden;
				}
				.cn__dashboard_property_card_name__type {
					position: relative;
					display: flex;
					align-items: center;
					gap: 20px;

					.cn__dashboard_property_card_name__type__name {
						position: relative;
						border: 0.3px solid #64748b;
						box-sizing: border-box;
						border-radius: 5px;
						display: inline-flex;
						padding: 4px 10px;
						margin: 10px 0;

						.cn__dashboard_property_card_name__type__name__title {
							font-weight: 400;
							font-size: 14px;
							line-height: 1;
							letter-spacing: 0.01em;
							color: #64748b;
						}
						.cn__dashboard_property_card_type-stars {
							display: inherit;
							margin-left: rem(5);

							path {
								fill: #efb008;
								fill-opacity: 1;
							}
						}
					}

					.cn__dashboard_property_card_name__type__info {
						position: relative;
						display: flex;
						align-items: center;
						gap: 16px;

						&__amenity {
							position: relative;
							display: flex;
							align-items: center;

							svg {
								flex-shrink: 0;
								path {
									fill: $color-slate-grey;
								}
							}

							&__info {
								position: relative;
								font-size: 14px;
								line-height: 1.2;
								letter-spacing: 0.01em;
								font-weight: 400;
								color: $color-dark-grey;
								margin-left: 7px;
							}

							&::after {
								position: absolute;
								content: "";
								width: 1px;
								height: 20px;
								background: $color-light-gray-input;
								right: -8px;
							}

							&:last-child {
								&::after {
									display: none;
								}
							}

							&:first-child {
								svg {
									margin-left: -2px;
								}
								.cn__dashboard_property_card_name__type__info__amenity__info {
									margin-left: 3px;
								}
							}

							&:nth-child(2) {
								svg {
									margin-left: -5px;
								}
								.cn__dashboard_property_card_name__type__info__amenity__info {
									margin-left: 2px;
								}
							}
						}
					}

					&.cn__dashboard_property_card_with_additional_headlines_count {
						display: none;
					}
				}

				.cn__dashboard_property_card_rating {
					position: absolute;
					right: 20px;
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					gap: 8px;

					@media (max-width: 622px) {
						top: rem(150);
						right: 10px;
					}

					.react-loading-skeleton {
						width: rem(100);
					}
					.cn__dashboard_property_card_grs-rating {
						display: flex;
						.cn__dashboard_property_card_grs-rating_container {
							.cn__dashboard_property_card_grs-rating_reviews {
								display: flex;
								span:first-child {
									margin-right: rem(5);
								}
							}
						}
					}
				}
				.cn__dashboard_property_card_rating-icon {
					margin-right: rem(5);
					path {
						fill: $color-black;
					}
					&.cn__dashboard_property_card_rating-icon__selected {
						path {
							fill: $color-primary;
						}
					}
				}
				color: $color-black;
				font-size: rem(18);
				font-weight: 600;
				line-height: 1.2;
				display: grid;
				grid-template-columns: 1fr max-content;
				span {
					display: -webkit-box;
					line-clamp: 1;
					box-orient: vertical;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
				&.cn__dashboard_property_card_name__labelled {
					column-gap: rem(0);
					max-width: 75%;
					@media (min-width: 600px) and (max-width: 750px) {
						max-width: 70%;
					}
					@media (min-width: 1090px) and (max-width: 1350px) {
						max-width: 70%;
					}
					@media (max-width: 600px) {
						max-width: 100%;
						width: 100%;
					}
				}
				&.cn__dashboard_property_card_name__withoutpartner{
						max-width: 100%;
						width: 100%;
				}
				&.cn__dashboard_property_card_name--small {
					max-width: 100%;
				}
				.cn__dashboard_property_card_sticker {
					display: block;
					position: initial;
					border-style: solid;
					border-color: $color-purple;
					border-width: rem(1);
				}
			}
			.cn__dashboard_property_card_additional-headlines {
				display: none;
				align-items: center;
				margin-top: rem(5); // 10
				color: $color-subtle-grey;
				font-family: $primary-font;
				font-size: rem(13);
				line-height: rem(20);
				letter-spacing: 0.02em;
			}
			/* .cn__dashboard_property_card_services {
                margin-top: rem(12);

                .react-loading-skeleton {
                    width: rem(50);
                }
                display: flex;
                .cn__dashboard_property_card_service {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    &:not(:last-child) {
                        padding-right: rem(10);
                        border-right: rem(1) solid $color-light-gray-input;
                    }
                    &:not(:first-child) {
                        padding-left: rem(10);
                    }
                }
            } */
			.cn__dashboard_property_card_services {
				position: relative;
				order: 4;

				&_top {
					position: relative;
					display: flex;
					align-items: center;
					gap: 20px;
					grid-row-gap: 5px;
					margin-top: 10px;
					flex-wrap: wrap;

					&_icon {
						position: relative;
						display: grid;
						place-items: center;
						width: 24px;
						height: 24px;
						svg {
							path {
								fill: $color-dark-grey;
							}
						}
					}
				}
			}
			.cn__dashboard_property_card_services-first {
				display: flex;
			}
			.cn__dashboard_property_card_activities {
				order: 4;
			}
			.cn__dashboard_property_card_promo-section {
				&.promo-section_christmas {
					.cn__dashboard_property_card_promo-label {
						background-color: #1c4028;
					}
				}
				&.promo-section_valentines {
					.cn__dashboard_property_card_promo-label {
						background-color: #f35597;
					}
				}
				.cn__dashboard_property_card_promo-label {
					display: flex;
					justify-content: center;
					align-items: center;
					width: rem(136);
					height: rem(22);
					margin-top: rem(17);
					background-color: $color-black;
					border-radius: rem(6);
					font-family: $primary-font;
					font-weight: 500;
					font-size: rem(12);
					color: $color-white;
					text-transform: uppercase;
					line-height: rem(16);
					.promo-ribbon_content {
						margin-left: rem(5);
					}
				}
			}
		}

		.cn__dashboard_property_card_footer {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			overflow: hidden;
			font-weight: 400;
			color: $color-black;
			gap: 13px !important;
			@media (min-width: 1460px) {
				margin-top: 0 !important;
			}
			@media (max-width: 600px) {
				// margin-top: 0 !important;
			}

			&--small {
				overflow: unset;
			}

			.cn__dashboard_property_card_footer_left {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				.cn__dashboard_property_card_services {
					position: relative;

					&_top {
						position: relative;
						display: flex;
						align-items: center;
						gap: 20px;
						margin-top: 5px;

						&_icon {
							position: relative;
							display: grid;
							place-items: center;
							width: 24px;
							height: 24px;
							svg {
								path {
									fill: $color-dark-grey;
								}
							}
						}
					}
				}
			}
			.cn__dashboard_property_card_activities {
				max-height: initial;
				line-height: initial;
				overflow: initial;
				// margin-bottom: rem(10);
				.cn__dashboard_property_card_activities_rail {
					padding-bottom: initial;
					overflow: inherit;
					flex-wrap: wrap;
				}
			}

			.cn__dashboard_property_card_price {
				display: grid;
				grid-template-columns: repeat(1, max-content);
				grid-template-rows: repeat(3, auto);
				// justify-content: flex-end;
				// align-items: center;
				font-family: $primary-font;
				font-size: 1rem;
				text-align: right;
				.react-loading-skeleton {
					width: rem(200);
				}

				&--small {
					position: relative;
				}

				.cn__dashboard_property_card_price-discount {
					position: relative;
					margin-top: rem(5);
					margin-bottom: rem(5) !important;
					// display: flex;
					display: grid;
					grid-template-columns: auto auto;
					grid-gap: 5px;
					align-items: center;
					padding: rem(3) rem(10) rem(3) rem(7);
					border-radius: rem(6);
					background-color: $color-vibrant-pink2;
					grid-column: 1 / span 3;
					justify-self: flex-end;
					font-family: $primary-font;
					font-weight: 600;
					font-size: rem(14);
					line-height: rem(21);
					letter-spacing: 0.02em;
					color: $color-white;
					text-transform: uppercase;

					.promo-ribbon__hover {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						left: -100%;
						font-weight: 600;
						font-size: 8px;
						line-height: 1.5;
						letter-spacing: 0.01em;
						color: $color-sea-green;
						background: url("/static/img/message.svg") no-repeat center center;
						background-size: contain;
						padding: 5px 8px 5px 5px;
					}
				}
				.cn__dashboard_property_card_price-original {
					color: $color-gray-font;
					text-decoration-line: line-through;
					// transform: translateY(1px);
					margin-right: rem(10);
					align-self: baseline;
				}
				.cn__dashboard_property_card_price-actual {
					font-size: rem(14);
					color: $color-black;
					align-self: baseline;
					display: flex;
					align-items: center;
					gap: 10px;
					margin-left: auto;
					&--small {
						gap: 0;
						align-items: flex-end;
						.strike-through-price {
							margin-right: 10px;
						}
					}

					.strike-through-price {
						min-height: 20px;
					}
					.strike-through-price__number {
						color: $color-subtle-grey;
						font-family: $primary-font;
						font-weight: 600;
						font-size: rem(14);
						line-height: 120%;
						text-align: right;
						letter-spacing: 0.01em;
						text-decoration-line: line-through;
					}

					.cn__dashboard_property_card_price-actual__rooms {
						color: $color-dark-grey;
						font-family: $primary-font;
						font-weight: 500;
						font-size: rem(14);
						line-height: rem(20);
						text-align: right;
						letter-spacing: 0.01em;
						min-height: 20px;
					}

					.cn__dashboard_property_card_price-actual__active {
						font-weight: 600;
						font-size: rem(18);
						line-height: 100%;
					}

					@media (max-width: 750px) {
						flex-direction: column;
						gap: 0;
					}
					@media (max-width: 600px) {
						flex-direction: row;
					}
				}
				.cn__dashboard_property_card_price-info {
					font-size: 10px;
					font-weight: 400;
					color: $color-subtle-grey;
				}
				.cn__dashboard_property_card_price-total {
					grid-column: 1 / span 3;
					color: $color-dark-grey;
					font-family: $primary-font;
					font-weight: 500;
					font-size: rem(14);
					line-height: rem(20);
					text-align: right;
					letter-spacing: 0.01em;
					margin-top: rem(5);
				}
			}
		}
		.cn__dashboard_property_card_footer {
			width: 100%;
			.cn__dashboard_property_card_footer_left {
				width: 100%;
				.cn__dashboard_property_card_services {
					display: flex;
					gap: 40px;

					.cn__dashboard_property_card_amenities {
						position: relative;
						width: auto;
						overflow: unset;
						max-height: unset;

						.cn__dashboard_property_card_amenities_rail {
							display: grid;
							grid-template-columns: 1fr 1fr 1fr;
							grid-column-gap: 30px;
							grid-row-gap: 15px;
							.cn__dashboard_property_card_amenities_amenity-entity {
								margin-right: 0;
							}
						}
						&::after {
							position: absolute;
							content: "";
							width: 1px;
							height: 100%;
							background: $color-grey-divider;
							right: -20px;
							top: 0;
						}
					}
					.cn__dashboard_property_card_services_top {
						margin-top: 0;
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						grid-column-gap: 30px;
						grid-row-gap: 15px;
					}
				}
			}
		}
	}
	.cn__dashboard_property_card_import_source--mobile {
		margin-bottom: 10px;
		width: fit-content;
		margin-left: auto;
	}
	.cn__dashboard_property_card_import_source {
		// z-index: 1;
		background: #555759;
		border-radius: 5px;
		color: $color-white;
		padding: rem(5) rem(10);
		font-weight: 700;
		font-size: 10px;
		line-height: 1.1;
		letter-spacing: 0.01em;
		order: 3;
		.cn__dashboard_property_card_import_source__red {
			color: red;
		}
		.cn__dashboard_property_card_import_source__green {
			color: green;
		}

		@media (max-width: 600px) {
			right: auto;
			left: 20px;
		}

		@media (min-width: 1460px) {
			right: auto;
			left: 20px;
		}
	}
	.cn__dashboard_property_card_editorpick {
		background: $color-cn-pink;
		border-radius: 5px;
		color: $color-white;
		padding: rem(5) rem(10);
		font-weight: 700;
		font-size: 10px;
		line-height: 1.1;
		letter-spacing: 0.01em;
		display: flex;
		align-items: center;
		order: 2;

		svg {
			margin-right: 5px;
		}
	}

	&_with-controls {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	&_controls {
		display: flex;
		justify-content: space-between;
		height: 45px;
		border-radius: 0 0 20px 20px;
		background-color: $color-light-gray;
		align-items: center;

		&_primary-control-section {
			display: flex;
			justify-content: space-around;
			align-items: center;
			padding-inline: 30px;

			&_typography {
				margin-inline: 15px;
				font-size: 12px;
				font-weight: 500;

				&_edit,
				&_delete {
					margin-inline: 15px;
					cursor: pointer;
					font-size: 12px;
					font-weight: 500;
				}
			}

			&_dates {
				display: flex;
				justify-content: space-around;
				align-items: center;
				&_calendar-icon {
					width: 30px;
					height: 30px;
				}
			}
		}
	}

	@media screen and (max-width: 682px) {
		&_with-controls {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		&_controls {
			display: flex;
			justify-content: space-between;
			height: 45px;
			border-radius: 0 0 20px 20px;
			background-color: $color-light-gray;
			align-items: center;

			&_primary-control-section {
				display: flex;
				justify-content: space-around;
				align-items: center;
				padding-right: 24px;
				padding-left: 0;

				&_typography {
					margin-inline: 0;
					font-size: 12px;
					cursor: pointer;

					&_edit,
					&_delete {
						height: 30px;
						width: 30px;
						margin-inline: 10px;
						cursor: pointer;
						path {
							fill: black;
						}
					}
					&_delete {
						width: 24px;
						height: 24px;
					}
				}
			}
		}
	}
}

.cn__dashboard_property_card_type {
	.react-loading-skeleton {
		width: rem(240);
		height: rem(23);
	}
	display: flex;
	align-items: center;
	// font-variant: all-small-caps;
	text-transform: capitalize;
	font-size: rem(14);
	line-height: rem(21);
	letter-spacing: 0.02em;
	margin-bottom: rem(5);
	color: $color-black;
	.cn__dashboard_property_card_type-stars {
		// margin-top: rem(2);
		display: inherit;
		margin-left: rem(5);
		// opacity: 0.7;
		padding-bottom: rem(1);
		// margin-left: rem(6);
		path {
			fill: $color-black;
			fill-opacity: 1;
		}
	}
	.cn__dashboard_property_card_type-wrapper {
		display: flex;
		align-items: center;
		.cn__dashboard_property_card_additional-headlines {
			margin-top: 0;
		}
	}
}

.cn__dashboard_property_card_distance {
	display: flex;
	color: $color-black;
	font-family: $primary-font;
	font-weight: 400;
	font-size: rem(14);
	line-height: rem(21);
	letter-spacing: 0.01em;
	transform: translateX(-5px);
	width: 75%;
	margin-top: 10px;
	@media (min-width: 1460px) {
		width: 100%;
	}
	@media (max-width: 600px) {
		width: 100%;
	}
	.cn__dashboard_property_card_distance-icon {
		margin-right: rem(5);
		margin-left: rem(5);
		transform: translateY(1px);
		width: rem(8);
		flex-shrink: 0;

		path {
			fill: $color-black;
		}
	}
	.cn__dashboard_property_card_distance_content {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		align-self: center;
		word-break: break-all;
	}
	.cn__dashboard_property_card_distance_neighbour {
		font-weight: 600;
	}
}

.cn__dashboard_property_card_free-cancellation-holder {
	width: 100%;
}
.cn__dashboard_property_card_free-cancellation-sticker {
	display: flex;
	align-items: center;
	background-color: $color-light-sea-green;
  	color: $color-sea-green;
	font-size: rem(10);
	padding: rem(4) rem(11);
	border-radius: 4px;
	&.cn__dashboard_property_card_free-cancellation-sticker--large {
		position: initial;
		width: 100%;
	}
	.cn__dashboard_property_card_free-cancellation-sticker_icon {
		margin-right: rem(7);
	}
}
.cn__dashboard_property_card_breakfast-included-sticker {
	display: flex;
	align-items: center;
	background-color: $color-light-purple;
  	color: $color-bright-purple;
	font-size: rem(10);
	padding: rem(4) rem(8);
	border-radius:  4px;

	svg {
		width: 15px;
		margin-right: 5px;
		height: auto;
	}

	&.cn__dashboard_property_card_breakfast-included-sticker--large {
		position: initial;
		width: max-content;
	}
	.cn__dashboard_property_card_breakfast-included-sticker_icon {
		margin-right: rem(5);
	}
}

.cn__dashboard_property_card_grs-rating {
	display: grid;
	grid-template-columns: repeat(2, auto);
	column-gap: rem(7);
	font-family: $primary-font;
	border-radius: 12px 12px 12px 0px;
	border: 1px solid #E0E0E3;
	color: var(--color-shade-black);
	background: var(--color-white);
	padding: 0px 10px;
	padding-left: 4px;
	.cn__dashboard_property_card_grs-rating_number {
		font-size: 14px;
		font-weight: 600;
		line-height: 150%;
		line-height: 1;
		letter-spacing: 0.01em;
		color: inherit;
		padding: 0px 7px;
		border-radius: 10px;
		align-content: center;
	}
	.cn__dashboard_property_card_grs-rating_container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding-left: 10px;
		color: inherit;
		border-left: 1px solid #E0E0E3;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 14px;
		.cn__dashboard_property_card_grs-rating_adjective {
			font-weight: 600;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: 0.16px;
		}
		.cn__dashboard_property_card_grs-rating_reviews {
			font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; 
            letter-spacing: 0.14px;
		}
	}
}

.cn__dashboard_property_card_activities {
	margin-top: rem(15);
	max-height: rem(29);
	line-height: rem(29);
	.cn__dashboard_property_card_activities_rail {
		gap: rem(5);
		padding-bottom: rem(5);
	}
	.cn__dashboard_property_card_activity {
		background: none;
		border: solid;
		border-width: 1px;
		border-color: $color-gray-font;
		color: $color-gray-font;
		font-size: 10px;
		font-weight: 500;
		line-height: rem(12);
		// background-image: linear-gradient(#f53959,#f35597);
		// background-size: 100% 200%;
		// background-position: 50% 100%;
		// background-clip: text;
		// -webkit-text-fill-color: transparent;
		// font-weight: 600;
		padding: rem(4) rem(8);
		border-radius: rem(18);
		// border: rem(1) solid $color-primary;
		white-space: nowrap;
		opacity: 0;
		visibility: hidden;
		&.cn__dashboard_property_card_activity--visible {
			opacity: 1;
			visibility: visible;
		}
	}
}

.cn__dashboard_property_card_amenities,
.cn__dashboard_property_card_activities {
	width: 100%;
	overflow: hidden;
	max-height: rem(25);
	line-height: rem(25);
	.cn__dashboard_property_card_amenities_amenity-entity_icon-pool {
		path {
			stroke: $color-black;
		}
		rect {
			fill: $color-black;
		}
	}
}
.cn__dashboard_property_card_amenities_rail,
.cn__dashboard_property_card_activities_rail {
	overflow-x: auto;
	overflow-y: hidden;
	display: flex;
	flex-flow: row nowrap;
}

.cn__dashboard_property_card_amenities_amenity-entity_popover {
	background: rgba(0, 0, 0, 0.7);
	color: $color-white;
	font-size: 10px;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: 0.01em;
	padding: rem(5) rem(10) !important;
	border-radius: rem(5);
}
.cn__dashboard_property_card_amenities_amenity-entity_popover2 {
	background: rgba(0, 0, 0, 0.7);
	color: $color-white;
	padding: rem(5) rem(10) !important;
	border-radius: rem(5);
	position: relative;
	top: -6px;

	&__inside {
		&__row {
			position: relative;
			display: flex;
			align-items: center;
			gap: 10px;
			font-size: 10px;
			line-height: 1.5;
			letter-spacing: 0.01em;
			margin-top: 5px;

			&:first-child {
				margin-top: 0;
			}

			span {
				font-weight: 600;
			}
		}
	}
}

.cn__dashboard_property_card_amenities_amenity-entity {
	display: grid;
	grid-template-columns: repeat(2, max-content);
	align-items: center;
	transition: 0.3s ease-out;
	opacity: 1;
	margin-right: rem(10);
	scroll-snap-align: end;
	scroll-snap-type: x mandatory;
	scroll-snap-stop: normal;
	&:last-child {
		border-top-right-radius: rem(30);
		border-bottom-right-radius: rem(30);
	}
	// &.cn__dashboard_property_card_amenities_amenity-entity--loaded {
	//   opacity: 1;
	// }
	&.cn__dashboard_property_card_amenities_amenity-entity--hover {
		transition: opacity 0.3s ease-out;
		.cn__dashboard_property_card_amenities_amenity-entity_icon {
			opacity: 1;
		}
	}
	.cn__dashboard_property_card_amenities_amenity-entity_icon {
		opacity: 0.7;
		height: rem(25);
		svg, img {
			width: 24px;
			height: 24px;
		}
	}
	.cn__dashboard_property_card_amenities_amenity-entity_name-container {
		overflow: hidden;
		transition: width 0.3s ease-out;
		scroll-snap-align: end;
		.cn__dashboard_property_card_amenities_amenity-entity_name {
			margin-top: rem(3);
			padding-left: rem(10);
			white-space: nowrap;
			font-family: $primary-font;
			font-weight: 500;
			font-size: rem(12);
			line-height: 1rem;
			letter-spacing: 0.015em;
			text-transform: uppercase;
			color: #555759;
			vertical-align: middle;
			scroll-snap-align: end;
		}
	}
}

.cn__dashboard_property_card_mobile-attrs {
	.cn__dashboard_property_card_amenities_amenity-entity {
		.cn__dashboard_property_card_amenities_amenity-entity_icon {
			opacity: 1;
		}
	}
	.cn__dashboard_property_card_grs-rating {
		.cn__dashboard_property_card_grs-rating_number {
			background-color: $color-white;
			color: $color-black;
		}
		.cn__dashboard_property_card_grs-rating_container {
			.cn__dashboard_property_card_grs-rating_adjective {
				color: $color-white;
			}
			.cn__dashboard_property_card_grs-rating_reviews {
				color: $color-light-gray-input;
			}
		}
	}
	.cn__dashboard_property_card_amenities {
		align-self: flex-end;
		justify-self: flex-end;
		display: flex;
		justify-content: flex-end;
		max-height: unset;
		line-height: unset;
		.cn__dashboard_property_card_amenities_rail {
			justify-content: flex-end;
			background: $color-white;
			box-shadow: inset 7.7px -7.7px 7.7px rgba(149, 149, 149, 0.1),
				inset -7.7px 7.7px 7.7px rgba(255, 255, 255, 0.1);
			backdrop-filter: blur(15.4px);
			&:first-child {
				border-top-left-radius: rem(30);
				border-bottom-left-radius: rem(30);
			}

			&:last-child {
				border-top-right-radius: rem(30);
				border-bottom-right-radius: rem(30);
			}
		}
		circle,
		rect {
			fill: $color-white;
		}

		path {
			stroke: $color-white;
		}

		.cn__dashboard_property_card_amenities_amenity-entity {
			// background: rgba(196, 196, 196, .1);
			// box-shadow: inset 7.7px -7.7px 7.7px rgba(149, 149, 149, .1), inset -7.7px 7.7px 7.7px rgba(255, 255, 255, .1);
			// backdrop-filter: blur(15.4px);
			padding: rem(5);
			margin-right: initial;
			&:nth-child(1) {
				border-top-left-radius: rem(30);
				border-bottom-left-radius: rem(30);
			}
		}

		.cn__dashboard_property_card_amenities_amenity-entity_icon-img {
			max-width: rem(24);
			max-height: rem(24);
		}

		.cn__dashboard_property_card_amenities_amenity-entity_icon-parking {
			path {
				fill: $color-white;
			}
		}

		.cn__dashboard_property_card_amenities_amenity-entity_icon-washing-machine {
			.washing-mashine-outlined-rect-1,
			.washing-mashine-outlined-circle-1 {
				stroke: $color-white;
				fill: transparent;
			}
			path {
				stroke: $color-white;
				fill: $color-white;
			}
			.washing-mashine-outlined-rect-2,
			.washing-mashine-outlined-circle-2 {
				fill: $color-white;
			}
		}

		.cn__dashboard_property_card_amenities_amenity-entity_icon-services {
			path:last-child {
				fill: $color-white;
			}
		}

		.cn__dashboard_property_card_amenities_amenity-entity_icon-pool {
			.pool-outlined-path-3,
			.pool-outlined-path-4 {
				fill: $color-white;
				stroke: transparent;
			}
			path {
				stroke: $color-white;
			}
			rect {
				fill: $color-white;
			}
		}

		.cn__dashboard_property_card_amenities_amenity-entity_icon-bath {
			.bath-outlined-path-2 {
				fill: $color-white;
			}
		}

		.cn__dashboard_property_card_amenities_amenity-entity_icon-cigarette {
			.cigarette-crossed-path-3,
			.cigarette-crossed-path-4 {
				fill: $color-white;
				stroke: transparent;
			}
		}

		.cn__dashboard_property_card_amenities_amenity-entity_icon-lotos {
			path:last-child {
				fill: $color-white;
				stroke: transparent;
			}
		}

		.cn__dashboard_property_card_amenities_amenity-entity_icon-refrigerator,
		.cn__dashboard_property_card_amenities_amenity-entity_icon-car-and-airplane,
		.cn__dashboard_property_card_amenities_amenity-entity_icon-oven,
		.cn__dashboard_property_card_amenities_amenity-entity_icon-stove,
		.cn__dashboard_property_card_amenities_amenity-entity_icon-balcony,
		.cn__dashboard_property_card_amenities_amenity-entity_icon-spoon-and-fork {
			path {
				fill: $color-white;
				stroke: transparent;
			}
		}

		.cn__dashboard_property_card_amenities_amenity-entity_icon-toy {
			mask {
				path {
					stroke: initial;
				}
			}
			path,
			.toy-outlined-rect-1 {
				fill: $color-white;
				stroke: transparent;
			}
			.toy-outlined-rect-2,
			.toy-outlined-rect-3 {
				fill: transparent;
				stroke: $color-white;
			}
		}

		.cn__dashboard_property_card_amenities_amenity-entity_icon-wheelchair {
			path {
				fill: $color-white;
				stroke: transparent;
			}
			ellipse {
				fill: $color-white;
			}
		}

		.cn__dashboard_property_card_amenities_amenity-entity_icon-barbell {
			rect {
				stroke: $color-white;
			}
			mask {
				path {
					stroke: transparent;
				}
			}
			path {
				fill: $color-white;
				stroke: transparent;
			}
		}
	}
}

.tiny-tooltip-container {
	max-width: 150px;
	background: #0f1a2a;
	// background: rgba(0, 0, 0, 0.7);
	border-radius: rem(11);
	position: relative;
	top: -6px;
	.tiny-tooltip-text {
		padding: 0;
		text-align: center;
		color: $color-white;
		font-family: $primary-font;
		font-weight: 400;
		font-size: rem(14);
		line-height: 150%;
	}
	.tiny-tooltip-close {
		transform: scale(0.715);
		position: absolute;
		top: 2px;
		right: 7px;
	}
}

.cn__dashboard_property_card_controls_toggle-btn {
	.switch {
		position: relative;
		display: inline-block;
		width: 121px;
		height: 31px;
		margin-top: 8px;
		margin-left: 21px;
	}

	.switch input {
		display: none;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 23px;
		width: 23px;
		left: 4px;
		bottom: 4px;
		background-color: #eeeef0;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #2196f3;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(90px);
		-ms-transform: translateX(90px);
		transform: translateX(90px);
		background-color: #f35597;
		z-index: 2;
	}

	/*------ ADDED CSS ---------*/
	.on {
		display: none;
	}

	.on,
	.off {
		color: black;
		position: absolute;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		font-size: 10px;
		font-family: Verdana, sans-serif;
		white-space: nowrap;
		transition: all 0.5s ease-in-out;
	}

	input:checked + .slider .on {
		display: block;
		right: -40px;
		transition: all 0.5s ease-in-out;
	}

	.off {
		left: 70px;
	}

	input:checked + .slider .off {
		display: none;
		transition: all 0.5s ease-in-out;
	}

	/*--------- END --------*/

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
}
