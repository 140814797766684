.overview {
    /* display: flex;
    flex-wrap: wrap;
    gap: 20px; */

    // display: grid;
    // grid-template-columns: repeat(3,minmax(0,1fr));
    // gap: 20px;

    // width: 100%;
    // margin: auto;
    // margin-top: -15px;
    // margin-bottom: 48px;
    // margin-top: 25px;

    // // 2, 3 Tiles mobile
    // &.overview--mobile:not(.overview--no-layout) {
    //     @media (max-width: 618px) {
    //         justify-content: center;
    //     }

    //     .overview-tile {
    //         grid-template-columns: 1fr;
    //         grid-template-rows: repeat(2, 1fr);
    //         row-gap: 5px;
    //         place-content: center;
    //         text-align: center;
    //         font-weight: 500;

    //         width: 91px;
    //         padding: 15px;
    //         font-size: 12px;
    //         min-height: 80px;

    //         .overview-tile_icon {
    //             align-self: flex-end;
    //             justify-self: center;
    //         }

    //         .overview-tile_desc {
    //             display: none;
    //         }

    //         @media (min-width: 365px) {
    //             width: 92px;
    //         }

    //         @media (min-width: 500px) {
    //             width: 25vw;
    //             padding: 2vw;
    //             font-size: 13px;
    //             min-height: 127px;
    //         }
    //     }
    // }

    // // 3 Tile tablet
    // &:not(.overview--short):not(.overview--no-layout) {
    //     &.overview--tablet {
    //         margin-top: 30px;

    //         @media (max-width: 655px) {
    //             justify-content: center;
    //         }

    //         .overview-tile {
    //             @media (min-width: 600px) {
    //                 grid-template-columns: 1fr;
    //                 grid-template-rows: repeat(2, 1fr);
    //                 row-gap: 5px;
    //                 place-content: center;
    //                 width: 25vw;
    //                 padding: 2vw;

    //                 font-size: 13px;
    //                 text-align: center;
    //                 font-weight: 500;

    //                 .overview-tile_icon {
    //                     align-self: flex-end;
    //                     justify-self: center;
    //                 }

    //                 .overview-tile_desc {
    //                     display: none;
    //                 }
    //             }

    //             /*@media (min-width: 655px) {
    //                 width: 283px;
    //                 column-gap: 12px;
    //                 padding: 20px;
    //                 font-size: 13px;

    //                 grid-template-columns: max-content 1fr;
    //                 grid-template-rows: initial;
    //                 column-gap: 8px;
    //                 place-content: initial;

    //                 text-align: initial;
    //                 font-weight: normal;

    //                 .overview-tile_icon {
    //                     align-self: initial;
    //                     justify-self: initial;
    //                 }

    //                 .overview-tile_desc {
    //                     display: block;
    //                 }
    //             }*/

    //             @media (min-width: 700px) {
    //                 width: 29vw;
    //                 padding: 2vw;
    //                 column-gap: 5px;
    //                 font-size: 1.3vw;

    //                 grid-template-columns: max-content 1fr;
    //                 grid-template-rows: initial;
    //                 column-gap: .5vw;
    //                 place-content: initial;

    //                 text-align: initial;

    //                 min-height: 90px;

    //                 .overview-tile_icon {
    //                     align-self: initial;
    //                     justify-self: initial;
    //                 }

    //                 .overview-tile_desc {
    //                     display: block;
    //                 }
    //             }

    //             @media (min-width: 874px) {
    //                 width: 29vw;
    //                 font-size: 1.3vw;
    //             }

    //             @media (min-width: 947px) {
    //                 width: 283px;
    //                 padding: 20px;
    //                 font-size: 13px;
    //                 grid-template-columns: max-content 1fr;
    //                 grid-template-rows: initial;
    //                 column-gap: .5vw;
    //                 place-content: initial;

    //                 text-align: initial;
    //                 font-weight: normal;

    //                 .overview-tile_icon {
    //                     align-self: initial;
    //                     justify-self: initial;
    //                 }

    //                 .overview-tile_desc {
    //                     display: block;
    //                 }
    //             }
    //         }
    //     }
    // }

    // // 2 Tile tablet
    // &.overview--short {
    //     &.overview--tablet {
    //         grid-template-columns: repeat(2, max-content);
    //         margin-top: 30px;
    //         .overview-tile {
    //             @media (min-width: 600px) {
    //                 width: 43vw;
    //                 padding: 3.5vw;
    //                 font-size: 2vw;
    //                 column-gap: 1vw;
    //                 grid-template-columns: max-content 1fr;
    //             }
    //             @media (min-width: 655px) {
    //                 width: 283px;
    //                 padding: 20px;
    //                 font-size: 13px;
    //                 column-gap: 12px;
    //             }
    //         }
    //     }
    // }

    // &.overview--desktop {
    //     margin-top: 0;
    //     margin-bottom: 61px;
    // }

    // // 1 Tile
    // &.overview--no-layout {
    //     .overview-tile {
    //         width: 283px;
    //         padding: 20px;
    //         font-size: 13px;
    //         grid-template-columns: max-content 1fr;
    //         grid-template-rows: initial;
    //         column-gap: 12px;
    //     }
    // }

    // // Base styles
    // .overview-tile {
    //     // margin-top: 15px;

    //     // &:not(:last-child) {
    //     //     margin-right: 15px;
    //     // }

    //     box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    //     0px 12px 42px -4px rgba(24, 39, 75, 0.12);
    //     border-radius: 10px;
    //     background-color: var(--color-subtle-white);
    //     min-height: 127px;
    //     display: grid;
    //     font-family: var(--primary-font);
    //     color: var(--color-black-grey);

    //     .overview-tile_title {
    //         font-weight: 600;
    //         color: var(--color-black);
    //     }

    //     .overview-tile_desc {
    //         margin-top: 5px;
    //         line-height: 20px;
    //         letter-spacing: 0.01em;
    //     }
    // }

    // // 3 Tile desktop
    // &:not(.overview--short):not(.overview--no-layout) {
    //     .overview-tile {
    //         @media (min-width: 1054px) {
    //             grid-template-columns: 1fr;
    //             grid-template-rows: repeat(2, 1fr);
    //             row-gap: 5px;
    //             place-content: center;
    //             width: 16vw;
    //             padding: 1.2vw;

    //             font-size: 13px;
    //             text-align: center;
    //             font-weight: 500;

    //             .overview-tile_icon {
    //                 align-self: flex-end;
    //                 justify-self: center;
    //             }

    //             .overview-tile_desc {
    //                 display: none;
    //             }
    //         }

    //         @media (min-width: 1180px) {
    //             width: 17vw;
    //             padding: 1vw;
    //             grid-template-columns: max-content 1fr;
    //             grid-template-rows: initial;
    //             column-gap: .5vw;
    //             place-content: initial;

    //             font-size: 10px;
    //             text-align: initial;
    //             font-weight: normal;

    //             .overview-tile_icon {
    //                 align-self: initial;
    //                 justify-self: initial;
    //             }

    //             .overview-tile_desc {
    //                 display: block;
    //             }
    //         }

    //         @media (min-width: 1255px) {
    //             width: 18vw;
    //             padding: 1.2vw;
    //             font-size: .9vw;
    //             column-gap: .5vw;
    //         }

    //         @media (min-width: 1345px) {
    //             width: 19vw;
    //             padding: 1.3vw;
    //         }

    //         @media (min-width: 1430px) {
    //             width: 283px;
    //             padding: 20px;
    //             font-size: 13px;
    //         }
    //     }
    // }

    // // 2 Tile desktop
    // &.overview--short {
    //     grid-template-columns: repeat(2, max-content);
    //     .overview-tile {
    //         @media (min-width: 1134px) {
    //             width: 283px;
    //             padding: 20px;
    //             font-size: 13px;
    //             column-gap: 12px;
    //             grid-template-columns: max-content 1fr;
    //         }
    //     }
    // }
    border-radius: 10px;

    padding-left: 20px;
   //padding-right: 20px;
    --column-gap: 40px;
    max-width: 100%;
    width: auto;
    display: table;

    @media screen  and (max-width: 600px) {
        width: 100%;
        padding: 0;
    }

    .overview_container {
        overflow: hidden;
        border-radius: inherit;
        background-color: inherit;
    }

    .overview_wrapper {
        display: grid;
        grid-template-columns: repeat(5, minmax(min-content, 1fr));
        justify-content: flex-start;
        background-color: inherit;
        margin-left: calc(var(--column-gap) / -2);
        margin-top: calc(var(--column-gap) / -2);
        overflow: inherit;

        @media screen  and (max-width: 600px) {
            display: grid;
            grid-template-columns: auto auto auto;
            width: 100%;
            padding: 0;
            margin: 0;
            flex-wrap: unset;
            justify-content: unset;
        }
    }
    .overview_non_hotel{
        .overview-tile{
            .overview-tile_icon{
                img , svg {
                    width: 30px;
                    height: 30px;
                    margin-left: 10px;
                }
            }
        }
    }
    .overview_highlights{
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: var(--color-black);
    }
    .overview-tile {
        display: flex;
        align-items: center;
        background-color: inherit;
        position: relative;
        padding-top: 25px;
        padding-bottom: 25px;
        flex-direction: column;
        margin-left: calc(var(--column-gap) / 2);
        margin-right: calc(var(--column-gap) / 2);
        margin-top: calc(var(--column-gap) / 2);

        .overview-tile_content {
            margin-left: 10px;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: var(--color-dark-grey);
            @media (min-width:600px) {
                max-width: 125px;
            }
        }

        .overview-tile_icon {
            img , svg {
                width: 54px;
                height: 54px;
                margin-left: 10px;
            }
        }


        @media screen  and (max-width: 600px) {
            width: 100%;
            padding: 15px 10px;
            margin: 0;

            &::before{display: none;}
            &::after{
                left: unset;
                top: unset;
                display: block;
                transform: none;
                width: calc(100% - 20px);
                height: 1px;
                bottom: 0;
            }

            &:last-child{
                &::after{display: none;}
            }
        }
    }
}

.overview-tile-icon_long-beds {
    width: 30px;
    height: 30px;
}

.overview-tile-icon_cancellation {
    width: 24px;
    height: 24px;
}
