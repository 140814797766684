.product-location {
    background-color: var(--color-white);
    color: var(--color-black);
    border-color: var(--color-field-grey);
    box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);

    .product-location_sub{
        display: flex;
        align-items: center;
    }

    .product-location_address{
        @media screen  and (max-width: 600px) {
            span{
                margin-right: 10px;
            }
        }
    }

    .product-location_distance {
        border-left-color: var(--color-subtle-grey);
        margin-left: 2px;

        @media screen  and (max-width: 600px) {
            position: relative;
            top: 4px;
            display: inline-flex;
        }
    }
    .product-location_distance_icon {
        path {
            fill: var(--color-slate-grey);
        }
    }
    .product-location_main{
        margin-top: 5px;
        .map-pin {
            div {
                display: flex;
                align-items: center;
                .map-pin_place-name {
                    max-width: 49vw;
                }
            }
        }
    }
    .product-location_header {
        color: var(--color-dark-grey);
        grid-template-columns: 1fr;
    }

    .product-location_map {
        border-radius: 20px;
        height: 100%;
        margin-bottom: 0;
    }

    .product-location_nearby {
        opacity: 0;
        animation: appear .5s ease-in-out forwards;
    }

    .product-location_extra {
        color: var(--color-dark-grey);

        @media screen  and (max-width: 600px) {
            justify-content: flex-start;
        }
    }

    .poi-category {
        opacity: 0;
        animation: appear .3s ease-in-out forwards;
    }

    .product-location_nearby_container {
        border-color: var(--color-light-grey);
        &.product-location_nearby_container_pio-category--extended {
            .poi-category {
                .poi-category_wrapper {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
        &.product-location_nearby_container--dual,
        &.product-location_nearby_container--triple,
        &.product-location_nearby_container--quadruple {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
        }
        &.product-location_nearby_container--triple {
            grid-template-rows: repeat(2, 50%);
            .poi-category {
                &:first-child {
                    grid-row: 1 / span 2;
                }
                &:not(:first-child) {
                    max-height: 150px;
                    .poi-category_wrapper {
                        height: 100%;
                        max-height: initial;
                    }
                }
            }
        }
        .product-location_nearby_container_tabs {
            * {
                font-family: var(--primary-font);
                text-transform: unset;
                font-weight: 600;
                font-variant: unset;
            }
            .MuiTab-root {
                min-width: fit-content;
                flex: 1;
            }
            .MuiTabs-indicator{
                height: 3px;
                background-color: #F35597;
                border-radius: 5px;
            }
            .Mui-selected{
                color: var(--color-pure-black);
            }
        }

        .product-location_nearby_container_tab-panel {
            border-top-color: var(--color-light-grey);
        }
    }
    .poi_name {
        display: inline-flex;
    }
    .poi_distance {
        color: var(--color-dark-grey);
        display: inline-flex;
    }

    .poi-category_wrapper {
        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: var(--color-shade-gray);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--color-subtle-grey);
            border-radius: 2px;
        }
        
    }

    .poi {
        --poi-height: 40px;
        font-family: var(--primary-font);
        margin-bottom: 10px;
        &:hover {
            background-color: var(--color-subtle-white);
        }
        &.poi--active {
            background-color: var(--color-champagne-pink);
        }
        &:last-child {
            margin-bottom: 0;
        }
        section:first-of-type {
            padding-top: 9px;
        }
    }

    & + .card--neighborhood {
        margin-top: 50px;

        .card__title{
            font-weight: 600;
            font-size: 23px;
            line-height: 1.2;
            letter-spacing: 0;
        }
    }
}